<template>
  <div class="consumer-products py-5">
    <div class="container">
      <h2 class="mb-5">Consumer Products:</h2>
      <div class="row">
        <div
          class="col-md-4 mb-5"
          v-for="product in consumerProducts"
          :key="product.id"
        >
            <div class="card h-100 text-center">
              <img
                :src="product.coverImg"
                class="card-img-top"
                alt="product.title"
              />
              <div class="card-body">
                <h5 class="card-title font-weight-bold">
                  {{ product.title }}
                </h5>
                <p class="card-text text-muted">{{ product.desc }}</p>
              </div>
              <div class="card-footer">
                <a :href="product.link" class="btn btn-warning" target="_blank">Get the App</a>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    consumerProducts() {
      return this.$store.getters.consumerProducts;
    }
  }
};
</script>

<style lang="scss">
.consumer-products {
    img {
      height: 200px;
    }
  .card-footer{
    background-color: white !important;
    border-top: none !important;
  }
}
</style>
