<template>
  <div class="privacy-page">
    <div class="page-header">
      <div class="page-header-overlay">
        <h1 class="display-4 text-uppercase">Privacy Policy</h1>
      </div>
    </div>
    <div class="page-content my-4">
      <div class="container">
        <div class="page-content-block">
          <h3>PRIVACY</h3>
          <p>
            Wtx Hub, (“Wtx Hub”, “us” or “we”) respects the privacy of the
            visitors to its Website located at http://www.WtxHub.com (the
            “Website”), and users of its Services. This Privacy Policy informs
            you of our policies and practices regarding the collection, use,
            storage and disclosure of any Personal Information and Anonymous
            Information you submit to us through the Website or Services. It
            also describes your choices regarding use, access and correction of
            your personal information. The use of information collected through
            our service shall be limited to the purpose of providing the service
            for which the Client has engaged Wtx Hub. Unless otherwise defined
            herein, capitalized terms shall have the meanings assigned to such
            terms set forth in the Wtx Hub Terms and Conditions that you can
            find at Terms and Conditions and which incorporate this Privacy
            Policy by reference.
          </p>
          <p>
            “Personal Information” is any information that allows us (or others)
            to identify an individual person and would include (for example)
            your name, address, email address, IP Address or phone number, as
            well as other non-public information that is associated with or
            directly linked to such information.
          </p>
          <p>
            An “Internet protocol address” or “IP Address” is a number that is
            automatically assigned to your computer when you use the Internet.
            In some cases, your IP Address stays the same from browser session
            to browser session; but if you use a consumer Internet access
            provider, your IP Address probably varies from session to session.
            We track IP Addresses solely in conjunction with session Cookies to
            analyze our web page flow and to determine the appropriate language
            settings for you based on your region if you did not previously
            indicate. You can find out more about the Cookies and other tracking
            technologies that we use and the purposes for which we use them in
            the section below headed ‘Cookies’.
          </p>
          <p>
            “Anonymous Information” means information or a combination of
            information that does not relate to or identify individual persons.
            We collect and use Personal Information and Anonymous Information as
            described in this Privacy Policy. We respect your right to privacy
            and will only process Personal Information (including any sensitive
            personal data) you provide us in accordance with the UK Data
            Protection Act 2018 (the “Act”) and any other applicable privacy
            laws.
          </p>
        </div>
        <div class="page-content-block">
          <h3>USER CONSENT</h3>
          <p>
            Please read this Privacy Policy carefully. By accessing and using
            our Website and Services, you agree to be legally bound by the terms
            of this Privacy Policy. In addition, for certain activities on the
            Website, we may further confirm your consent by asking you to
            expressly consent to the collection, processing, storage and use of
            your Personal Information according to this Privacy Policy. If you
            do not agree with the terms of this Privacy Policy, please refrain
            from using our Website or accessing our Services.
          </p>
        </div>
        <div class="page-content-block">
          <h3>INTERNATIONAL TRANSFERS</h3>
          <p>
            Your Personal Information will be processed by Wtx Hub and other
            third party data processors for the purposes identified and
            described in this Privacy Policy only. By accessing or using our
            Website or Services or otherwise providing Personal Information to
            us, you consent to the processing, transfer and storage of your
            information in and to the designated location assigned by Wtx Hub
            for these purposes only. Please be aware that countries, which are
            located outside the EEA, may not offer the same level of data
            protection as countries located in the EEA, although our collection,
            storage and use of your Personal Information will continue to be
            governed by this Privacy Policy.
          </p>
          <p>
            Wtx Hub, complies with the General Data Protection Regulation (the
            “GDPR”). To learn more about GDPR, please visit
            https://edps.europa.eu/data-protection/data-protection/legislation/history-general-data-protection-regulation_en.
            Wtx Hub, as a “Data Controller” is responsible for the processing of
            personal data it receives from users of its websites and services,
            under the GDPR. Wtx Hub may subsequently transfer personal data to
            third parties acting as an agent on its behalf (“Data Processors”).
            Wtx Hub complies with the GDPR for all onward transfers of personal
            data from the EU and Swiss individuals, including the onward
            transfer liability provisions. With respect to personal data
            received or transferred Wtx Hub may be required to disclose personal
            data in response to lawful requests by public authorities, including
            to meet national security or law enforcement requirements. You may
            revoke your consent at any time by contacting Wtx Hub (address
            below) or by deleting the Personal Information you have submitted
            through our Website or Services.
          </p>
        </div>
        <div class="page-content-block">
          <h3>COLLECTION OF PERSONAL INFORMATION</h3>
          <p>
            The Personal Information we gather from you helps us learn about
            your visit to the Website or use of the Services. We use this
            information to provide the Services to you, to better tailor the
            features, performance and support of our products and services, to
            answer your requests, questions and concerns, and to offer you
            additional information and opportunities.
          </p>
        </div>
        <div class="page-content-block">
          <h3>PERSONAL INFORMATION YOU PROVIDE TO US</h3>
          <p>
            We collect Personal Information that you submit to us voluntarily
            through our Website, Applications or Services. When you register on
            the Website, we will collect your full name and username, a
            password, and an email address. We use these data to create your
            account and to provide the Services to you. When you use the
            Service, we collect your user ID, email address and IP address. We
            collect this information to better tailor the features, performance
            and support of our products and services. When you create your
            profile on the Website after registering, we will collect on an
            optional basis other Personal Information, such as information about
            your hobbies, interests, additional contact information, etc. You do
            not have to provide this additional information but if you do, we
            will use this information to better tailor the features, performance
            and support of our products and services. When you order products or
            services on the Website, we will collect all information necessary
            to complete the transaction, including your name, credit card
            information, billing information and shipping information. When you
            subscribe to a Wtx Hub newsletter, we will collect your email
            address to provide you with promotional information about our
            services where you have agreed to receive such communications. You
            may unsubscribe from the newsletter service at any time by following
            the unsubscribe instructions provided in the newsletter you receive
            or by contacting us. When you contact us to submit comments,
            questions or feedback using the forms on the Website or in the
            Services, we will collect your name, email address and your comment,
            question or feedback to answer your request, questions or concerns.
            When you voluntarily participate in one of our surveys, we may
            collect additional profile information such as demographic
            information. We use this information to better tailor the features,
            performance and support of our products and services. When you
            contact us using the request forms on the Website or in the
            Services, we will collect your name, email address and your comment
            or question that you enter into the form to answer your request,
            questions or concerns. When you post messages on the message boards
            of the Website or through the Services, the information contained in
            your posting will be stored on our servers and made public so other
            users will be able to see it. When you contact us by sending us an
            email, we will collect and store your email address and any
            information contained in the email you send us and any information
            attached to the email for so long as is necessary. This information
            is used to answer your email and address any questions and concerns
            you may have. We may also collect, from you, the following personal
            information about your contacts: Name and address of a gift
            recipient, in order to fulfill a purchase order When you provide us
            with personal information about your contacts we will only use this
            information for the specific reason for which it is provided. If you
            believe that one of your contacts has provided us with your personal
            information and you would like to request that it be removed from
            our database, please contact us at contact@WtxHub.com.
          </p>
        </div>
        <div class="page-content-block">
          <h3>PERSONAL INFORMATION COLLECTED VIA TRACKING TECHNOLOGY</h3>
          <p>
            As you navigate our Website or App, certain information may also be
            passively collected, including your IP address, browser type, domain
            names, access times, operating system, mouse clicks, mouse
            movements, scrolling activity. We also use Cookies and navigational
            data like Uniform Resource Locators (URL) to gather information
            regarding the date and time of your visit and the solutions and
            information for which you searched and which you viewed. This type
            of information is collected to analyze the use of our Services and
            solutions and to make them more useful to you. Like other aspects of
            our Services, we use a third party service provider for some or all
            of this type of data collection and analysis. These third party
            service providers may also independently collect information about
            you as described in “Disclosure of Personal Information” below. For
            more detailed information about Cookies and other online tracking
            tools that we use and the purposes for which we use them, please see
            our section below headed “Cookies”.
          </p>
        </div>
        <div class="page-content-block">
          <h3>PERSONAL INFORMATION WE RECEIVE FROM OTHER SOURCES</h3>
          <p>
            We may receive Personal Information about you from other sources
            like mobile device, telephone or fax or from companies that provide
            our services by way of a co-branded or private-labeled website
            (“Partner Companies”), and for some of our Services, this includes
            location based services applicable for certain features in the
            Services. If you choose to disable location based services some
            features of the Services may not work properly. We may add this
            information to the information we have already collected from you
            via our Website or Services in order to improve the products and
            services we provide.
          </p>
        </div>
        <div class="page-content-block">
          <h3>USE OF INFORMATION</h3>
          <p>
            In general, Personal Information you submit to us is used either to
            respond to requests that you make, or to aid us in serving you
            better. We may also use your Personal Information to, provide you
            with the Wtx Hub newsletters, surveys, or promotional emails
            regarding changes and upgrades to our products and services and for
            other marketing purposes of Wtx Hub or our partners, should you
            request and consent to receive such communications from us. For more
            information about how to change your preferences and to unsubscribe
            from promotional communications, please see the section below headed
            “Your Choices Regarding Your Personal Information”.

            <br /><br />

            We may create Anonymous Information records from Personal
            Information by excluding information (such as your name) that makes
            the information personally identifiable to you. We may use this
            Anonymous Information to analyze request patterns and usage patterns
            so that we may enhance our products and services. We may disclose
            this Anonymous Information to third parties contracted by Wtx Hub to
            provide marketing services designed to promote our products and
            services. Wtx Hub reserves the right to use and disclose Anonymous
            Information to third parties in its discretion.
          </p>
        </div>
        <div class="page-content-block">
          <h3>FEEDBACK</h3>
          <p>
            If you provide feedback on any of our products and services to us,
            we may use such feedback for any purpose, provided we will not
            associate such feedback with your Personal Information. Wtx Hub will
            collect any information contained in such communication and will
            treat the Personal Information in such communication in accordance
            with this Privacy Policy.
          </p>
        </div>
        <div class="page-content-block">
          <h3>DISCLOSURE OF PERSONAL INFORMATION</h3>
          <p>
            Except as otherwise stated in this Privacy Policy, we do not trade,
            rent, or share your Personal Information with third parties and we
            will not disclose financial information that is part of your
            Personal Information unless you ask us to do so.
            <br /><br />
            We may share your Personal Information with third parties to provide
            you with the products and services that we offer you through the
            Website or Services for payment processing, shipping and
            distribution processing, account inquiries, and marketing analytics.
            These third parties are required not to use your Personal
            Information other than to provide the products and services
            requested by Wtx Hub and are bound by confidentiality agreements
            with regard to their use of such information. You expressly consent
            to the sharing of your Personal Information with our contractors and
            third-party service providers (“Data Processors”) for the sole
            purpose of providing products and services to you.
            <br /><br />
            We may enter into co-branding or co-promotional agreements with
            third parties pursuant to which we may share Personal Information
            with such third parties. For example, we may enter into agreements
            with Partner Companies pursuant to which we provide the Partner
            Companies with a URL and a customer registration page co-branded
            with, or private labeled by, the Partner Companies, and the Partner
            Companies distributes and promotes the URL to its customers. A
            Partner Company may have access to Personal Information that we
            collect from their customers. As a result, if you register on the
            Website or use our Services through a Partner Company, we may
            provide your Personal Information to the Partner Company. Please
            note, however, that we do not control the privacy practices of these
            third parties.
            <br /><br />
            If another company acquires our company or our assets, that company
            will possess the Personal Information collected by us and it will
            assume the rights and obligations regarding your Personal
            Information as described in this Privacy Policy. Regardless of any
            choices you make regarding your Personal Information (as described
            below), we may disclose your Personal Information if we believe in
            good faith that such disclosure is necessary to (a) comply with
            relevant laws or to respond to subpoenas or warrants served on Wtx
            Hub; (b) to protect and defend the rights or property of Wtx Hub or
            users of our Services; or (c) lawful requests by public authorities,
            including to meet national security or law enforcement requirements.
          </p>
        </div>
        <div class="page-content-block">
          <h3>YOUR PRIVACY RIGHTS</h3>
          <p>
            Upon request Wtx Hub will provide you with information about whether
            we hold any of your personal information. You may access, correct,
            or request deletion of your personal information by logging in to
            your account and updating your personal information and confirming
            it by selecting the ‘Save’ button, contacting us at
            contact@WtxHub.com. <br /><br />
            Wtx Hub acknowledges that you have the right to access your personal
            information. Wtx Hub has no direct relationship with the individuals
            whose personal data it processes. An individual who seeks access, or
            who seeks to correct, amend, or delete inaccurate data should direct
            their query to the Wtx Hub Client Support at contact@WtxHub.com. If
            requested to remove data, we will respond within a reasonable
            timeframe. We will retain personal data we process on behalf of our
            Clients for as long as needed to provide services to our Client.
            <br /><br />
            In certain circumstances we may be required by law to retain your
            personal information, or may need to retain your personal
            information in order to continue providing a service.
          </p>
        </div>
        <div class="page-content-block">
          <h3>COOKIES AND TRACKING TECHNOLOGIES</h3>
          <p>
            Wtx Hub and its partners use cookies or similar technologies to
            analyze trends, administer the Website, track users’ movements
            around the Website, and to gather demographic information about our
            user base as a whole. You can control the use of cookies at the
            individual browser level, but if you choose to disable cookies, it
            may limit your use of certain features or functions on our Website
            or service. We may use Cookies to collect information about you.
          </p>
        </div>
        <div class="page-content-block">
          <h3>WHAT IS A COOKIE?</h3>
          <p>
            “Cookies” are small pieces of information that a web site sends to
            your computer’s hard drive while you are viewing a web site. For
            example, we may use both session Cookies (which expire once you
            close your web browser) and persistent Cookies (which stay on your
            computer until you delete them) to provide you with a more personal
            and interactive experience on the Website or Services. Persistent
            Cookies can be removed by following Internet browser help file
            directions. If you choose to disable Cookies, some areas of the
            Website or Services may not work properly. We encode our Cookies so
            that only we can interpret the information stored in them. For more
            information about removing or rejecting Cookies, please see “Your
            Choices Regarding Your Personal Information” below. Wtx Hub may also
            collect information via clear gifs or web beacons (also known as
            “tracking pixels”) that help us better manage content on Website and
            Services by informing us what content is effective. Clear gifs are
            tiny graphics with a unique identifier, similar in function to
            Cookies, and are used to track the online movements of Web users. In
            contrast to Cookies, which are stored on a user’s computer hard
            drive, clear gifs are embedded invisibly on Web pages.

            <br /><br />
          </p>
        </div>
        <div class="page-content-block">
          <h3>HOW DO WE USE COOKIES?</h3>
          <p>
            Cookies do lots of different jobs on our Website, such as letting
            you navigate between pages efficiently, remembering your
            preferences, remembering your email and password, letting us analyze
            how well our Website is performing, and improving your experience.
            They also help ensure that advertisements you see whilst you are on
            our Website are more relevant to you and your interests
          </p>
        </div>
        <div class="page-content-block">
          <h3>WHAT COOKIES DO WE USE?</h3>
          <p>
            Below we have provided a list of the categories of Cookies found on
            our Website, and a description of what those Cookies do. Some of
            these Cookies are essential to the operation of our Services. Others
            are not essential, but help to improve our Website by collecting
            [anonymous] user information, or try and improve your experience of
            our Website by remembering your choices. We also use cookies, which
            collect information about your browsing habits in order to try, and
            display advertising, which is relevant to you and your interests.
          </p>
        </div>
        <div class="page-content-block">
          <h3>We use the following types of Cookies:</h3>
          <p>
            Strictly Necessary Cookies We use a number of Cookies, which are
            essential to the operation of our Website and Services. For example,
            these types of Cookies enable you to log into secure areas of the
            Services, provide the necessary security when you access our Website
            or Services, and help ensure the content of the pages you request
            load quickly. Without these cookies, services that you have asked
            for cannot be provided.
            <br /><br />
            Functional Cookies: Functional Cookies are ones that we set to help
            the Services work the way you expect them to. Some Cookies are
            necessary to enable you to move around our Website and/or to use the
            Services. Without these Cookies, we can’t provide the Services you
            have asked for, like processing payments made via our online store.
            <br /><br />
            We use other Cookies to help recognize and remember you when you are
            logged into our Services so we can remember your settings and
            preferences, such as your language and region or logged in state.
            These Cookies also may help us provide services you have asked for.
            <br /><br />
            As described below, you may disable any of these functional Cookies;
            but if you do so, then various functions of the Services may be
            unavailable to you or may not work the way you want them to.
          </p>
        </div>
        <div class="page-content-block">
          <h3>PERFORMANCE AND ANALYTICAL COOKIES:</h3>
          <p>
            Advertising and Web Analytic Tools <br /><br />
            As is true of most websites, we gather certain information
            automatically. This information may include Internet protocol (IP)
            addresses, browser type, Internet service provider (ISP),
            referring/exit pages, the files viewed on our site (e.g., HTML
            pages, graphics, etc.), operating system, date/time stamp, and/or
            clickstream data to analyze trends in the aggregate and administer
            the site.
            <br /><br />
            We use third-party advertising and/or web analytics companies to
            serve ads and/or to provide us web analytics when you visit our
            Website or Services. These companies may use information about your
            visits to this and other websites (e.g., through the use of Cookies)
            in order to provide advertisements about goods and services of
            interest to you, to provide us analyses of how our Website is used.
            Although we do not authorize these third parties to collect your
            Personal Information from our Website or Services, they may
            associate data collected about your use of our Website or Services
            with the Personal Information they have collected about you from
            other websites.
            <br /><br />
            Google Analytics Our Website uses Google Analytics, a web analytics
            service provided by Google, Inc. (“Google”). Google Analytics uses
            Cookies to help the Website analyze how users use the Website and
            Services. The information generated by the Cookie about your use of
            the Website and Services will be transmitted to and stored by
            Google.
            <br /><br />
            In case IP-anonymization is activated on this website, your IP
            Address will be truncated within the area of Member States of the
            European Union or other parties to the Agreement on the European
            Economic Area. Only in exceptional cases the whole IP Address will
            be first transferred to a Google server in the USA and truncated
            there. The IP-anonymization is active on this Website.
            <br /><br />
            Google will use this information on behalf of the operator of this
            Website for the purpose of evaluating your use of the Website,
            compiling reports on website activity for website operators and
            providing them other services relating to Website activity and
            Internet usage.
            <br /><br />
            The IP Address that your Browser conveys within the scope of Google
            Analytics will not be associated with any other data held by Google.
            You may refuse the use of cookies by selecting the appropriate
            settings on your browser, however please note that if you do this
            you may not be able to use the full functionality of this Website.
            You can also opt-out from being tracked by Google Analytics with
            effect for the future by downloading and installing Google Analytics
            Opt-out Browser Add-on for your current web browser:
            http://tools.google.com/dlpage/gaoptout?hl=en
          </p>
        </div>
        <div class="page-content-block">
          <h3>For further information please visit:</h3>
          <ul>
            <li>
              <a
                href="http://www.google.com/analytics/terms/us.html"
                target="_blank"
                >http://www.google.com/analytics/terms/us.html</a
              >
            </li>
            <li>
              <a
                href="https://support.google.com/analytics/answer/6004245?hl=en"
                target="_blank"
                >https://support.google.com/analytics/answer/6004245?hl=en</a
              >
            </li>
          </ul>
        </div>
        <div class="page-content-block">
          <h3>YOUR CHOICES REGARDING YOUR PERSONAL INFORMATION</h3>
          <p>
            We offer you the following choices regarding the collection,
            storage, use, and sharing of your Personal Information: <br /><br />

            Promotional Communications: You have the right to ask us not to
            process Personal Information for marketing or promotional purposes.
            We will usually inform you (before collecting your data) if we
            intend to use your data for such purposes or if we intend to
            disclose your information to any third party for such purposes. You
            can exercise your right to prevent such processing by checking
            certain boxes on the forms we use to collect your data. When you
            receive promotional communications from us, you may indicate a
            preference to stop receiving further communications from us and you
            will have the opportunity to “opt-out” by following the unsubscribe
            instructions provided in the communication you receive or by
            contacting us directly (please see contact information below).
            Despite your indicated preferences, we may still send you
            non-promotional communications regarding the services we provide to
            you, such as transaction reports, and notices regarding changes to
            the Website or Services. In any case we may send updates to our
            Terms of Use, Privacy Policy or other policies.
            <br /><br />
            Cookies:Most web browsers are set to accept Cookies by default. If
            you prefer, you can usually choose to set your browser to remove or
            reject browser Cookies.
          </p>
        </div>
        <div class="page-content-block">
          <h3>MANAGING COOKIES</h3>
          <p>
            You can block cookies by activating the setting on your browser that
            allows you to refuse the setting of all or some cookies. However, if
            you use your browser settings to block all cookies (including
            essential cookies) you may not be able to access all or parts of our
            site.
          </p>
        </div>
        <div class="page-content-block">
          <h3>REGARDING MINORS</h3>
          <p>
            If you have reason to believe that a child under the age of 13 has
            provided Personal Information to us, please contact us, and we will
            endeavor to delete that information and terminate the child’s
            account from our databases. We do not intentionally gather Personal
            Information about visitors who are under the age of 13.
          </p>
        </div>
        <div class="page-content-block">
          <h3>OTHER SITES</h3>
          <p>
            Our provision of a link to any other website or location is for your
            convenience and does not signify our endorsement of such other
            website or location or its contents. We have no control over, do not
            review, and cannot be responsible for these outside websites or
            their content. Please be aware that the terms of our Privacy Policy
            do not apply to these outside websites.
          </p>
        </div>
        <div class="page-content-block">
          <h3>MOBILE APPLICATION</h3>
          <p>
            When you download and use our Services, we automatically collect
            information on the type of device you use, operating system version,
            and the device identifier (or “UDID”).
            <br /><br />
            We do not ask you for, access or track any location based
            information from your mobile device at any time while downloading or
            using our Mobile Apps or services.
            <br /><br />
            We use mobile analytics software to allow us to better understand
            the functionality of our Mobile Software on your phone. This
            software may record information such as how often you use the
            application, the events that occur within the application,
            aggregated usage, performance data, and where the application was
            downloaded from. We do not link the information we store within the
            analytics software to any personally identifiable information you
            submit within the mobile app.
          </p>
        </div>
        <div class="page-content-block">
          <h3>SECURITY</h3>
          <p>
            Wtx Hub is committed to protecting the security of your Personal
            Information. We use a variety of industry-standard security
            technologies and procedures to help protect your Personal
            Information from unauthorized or unlawful access, use, or disclosure
            and against accidental loss or destruction of, or damage to, such
            Personal Information.
            <br /><br />
            Wtx Hub has implemented industry-standard electronic means of
            protecting your Personal Information. We store Personal Information
            behind a computer firewall, a barrier designed to prevent outsiders
            from accessing our servers, and we have implemented technology to
            detect intrusions. We also require you to enter a password to access
            your account information. In addition, Wtx Hub protects your
            Personal Information from unauthorized physical access by storing
            your Personal Information in a controlled facility.
            <br /><br />
            Except as provided elsewhere in this Privacy Policy, Wtx Hub limits
            access to Personal Information in electronic databases to those
            persons (including employees and contractors) in Wtx Hub’s
            organization who have a business need for such access. Lastly, we
            conduct periodic internal audits and vulnerability scans to ensure
            compliance with these security measures and generate security
            incident logs that track Information useful in screening for
            attempted abuse of or unauthorized access to your Personal
            Information.
            <br /><br />
            Even though Wtx Hub has taken significant steps to ensure that your
            Personal Information is not intercepted, accessed, used, or
            disclosed by unauthorized persons, you should know that Wtx Hub
            cannot fully eliminate security risks associated with Personal
            Information. If you have any questions about the security of your
            personal information, you can contact us at contact@WtxHub.com.
          </p>
        </div>
        <div class="page-content-block">
          <h3>CONTACT US</h3>
          <p>
            Should you have any questions or concerns regarding this Privacy
            Policy, please contact us through Customer Support or:
          </p>
          <a href="mailto:contact@WtxHub.com" target="_blank"
            >contact@WtxHub.com</a
          >
        </div>
        <div class="page-content-block">
          <h3>PRIVACY POLICY UPDATES</h3>
          <p>
            This Privacy Statement is a living document. As part of the Wtx Hub
            Terms and Conditions, this Privacy Policy is subject to reasonable
            amendments, for example to fulfill new and applicable statutory
            requirements and regulations or in case of new services, in
            accordance with the terms of the Terms and Conditions. If we make
            material changes to this Privacy Policy that expand our rights to
            use your Personal Information, we will notify you to seek your
            consent either through email, or a prominent posting on our Website,
            or as otherwise required by law. We encourage you to periodically
            review this page for the latest information on our privacy
            practices.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.privacy-page {
  min-height: 800px;

  .page-header {
    min-height: 500px;
    position: relative;
    background-image: url("../assets/privacy.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }

    .page-header-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 500px;
      background: rgba(0, 0, 0, 0.7);
    }
  }
}

.page-content {
  p {
    font-size: 1rem;
    margin: 20px 0;
  }

  h3 {
    &::before {
      content: "|";
      font-size: 3rem;
      color: orange;
      margin-right: 15px;
    }
  }
}
</style>
