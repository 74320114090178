<template>
  <div class="appuser-page">
    <div class="page-header">
      <div class="page-header-overlay">
        <h1 class="display-4 text-uppercase">application end user agreement</h1>
      </div>
    </div>
    <div class="page-content my-4">
      <div class="container">
        <div class="page-content-block">
          <h3>APPLICATION END USER</h3>
          <p>
            This End User License Agreement (“EULA”) is a binding contract
            between Wtx Hub (“Wtx Hub”) and you (“You”), as a user of the Wtx
            Hub Applications (the “Licensed Applications”) downloaded from the
            Google Play, Amazon Store, Apple Store or the Wtx Hub website
            marketplace. The Licensed Applications is licensed, not sold, to You
            for use only under the terms of this EULA. This EULA is between You
            and Wtx Hub, and Wtx Hub is solely responsible for the Licensed
            Applications and the content thereof. Wtx Hub reserves all rights
            not expressly granted to You.
          </p>
          <p>
            a. Scope of License: Wtx Hub grants to You a non-transferable,
            non-exclusive, revocable license to use the Licensed Applications on
            the mobile device that You own or control and as permitted by the
            Usage Rules set forth in the Google Play Terms of Service (the
            “Usage Rules”). This license does not allow You to use the Licensed
            Applications on any mobile device that You do not own or control,
            and You may not distribute or make the Licensed Applications
            available over a network where it could be used by multiple devices
            at the same time. You may not rent, lease, lend, sell, redistribute
            or sublicense the Licensed Applications. You may not copy (except as
            expressly permitted by this license and the Usage Rules), decompile,
            reverse engineer, disassemble, attempt to derive the source code of,
            modify, or create derivative works of the Licensed Applications, any
            updates, or any part thereof (except as and only to the extent any
            foregoing restriction is prohibited by applicable law or to the
            extent as may be permitted by the licensing terms governing use of
            any open sourced components included with the Licensed
            Applications). Any attempt to do so is a violation of the rights of
            Wtx Hub. If You breach this restriction, You may be subject to
            prosecution and damages. The terms of this EULA will govern any
            upgrades provided by Wtx Hub that replace or supplement the original
            Licensed Applications, unless such upgrade is accompanied by a
            separate license, in which case the terms of that license will
            govern.
          </p>

          <p>
            b. Consent to Use of Content: You agree that Wtx Hub may collect and
            use technical data and related information, including but not
            limited to technical information about Your device, system and
            Applications software, and peripherals, that is gathered
            periodically to facilitate the provision of software updates,
            product support and other services to You (if any) related to the
            Licensed Applications (collectively, “Data”). You may also
            voluntarily provide information, feedback, images, and other content
            in connection with your use of the Licensed Applications (together
            with the Data, collectively, “Content”). You agree that Wtx Hub may
            use the Content to improve its products and services or to provide
            products and services to You, and You hereby grant Wtx Hub a
            nonexclusive, worldwide, royalty-free, fully paid-up, transferable
            and sublicensable license in and to the Content, including all
            intellectual property rights therein, for Wtx Hub to use, modify and
            create derivative works of the same in connection with or related to
            any business purposes. You represent and warrant to Wtx Hub that (i)
            you have the necessary rights to grant the licenses and rights in
            and to the Content, and (ii) the Content and Wtx Hub’s use thereof
            as permitted in this EULA will not infringe, violate or
            misappropriate any third party right. Your personal information will
            be collected and processed in accordance with the terms of Wtx Hub’s
            Privacy Policy, available at www.wtxhub.com\privacy.
          </p>
          <p>
            c. Termination. The EULA is effective until terminated by You or Wtx
            Hub. Your rights under this EULA will terminate automatically
            without notice from Wtx Hub if You fail to comply with any term of
            this EULA. Upon termination of this EULA, You shall cease all use of
            the Licensed Applications, and destroy all copies, full or partial,
            of the Licensed Applications.
          </p>
          <p>
            d. Services; Third Party Materials. The Licensed Applications may
            enable access to Wtx Hub’s and third party services and web sites
            (collectively and individually, “Services”). Use of the Services may
            require Internet access and that You accept additional terms of
            service. The information provided by the Licensed Applications is
            provided to You for informational and reference purposes only. While
            every effort has been made to ensure the quality and accuracy of
            information displayed through the Licensed Applications, Wtx Hub
            shall not be held responsible for incomplete, inaccurate, invalid or
            incorrect information displayed through the Licensed Applications.
          </p>
          <p>
            Certain Services may display, include or make available content,
            data, information, Applications or materials from third parties
            (“Third Party Materials”) or provide links to certain third party
            web sites. By using the Services, You acknowledge and agree that Wtx
            Hub is not responsible for examining or evaluating the content,
            accuracy, completeness, timeliness, validity, copyright compliance,
            legality, decency, quality or any other aspect of such Third Party
            Materials or web sites. Wtx Hub does not warrant or endorse and does
            not assume and will not have any liability or responsibility to You
            or any other person for any third party Services or Third Party
            Materials. Third Party Materials and links to other web sites are
            provided solely as a convenience to You. Neither Wtx Hub, nor any of
            its content providers, guarantees the availability, accuracy,
            completeness, or reliability of any Services. You should exercise
            judgment in your use of any Services.
          </p>

          <p>
            You agree that Services contain proprietary content, information and
            material that is protected by applicable intellectual property and
            other laws, including but not limited to copyright, and that You
            will not use such proprietary content, information or materials in
            any way whatsoever except for permitted use of the Services. No
            portion of the Services may be reproduced in any form or by any
            means. You agree not to modify, rent, lease, loan, sell, distribute,
            or create derivative works based on the Services, in any manner, and
            You shall not exploit the Services in any unauthorized way
            whatsoever, including but not limited to, by trespass or burdening
            network capacity. You further agree not to use the Services in any
            manner to harass, abuse, stalk, threaten, defame or otherwise
            infringe or violate the rights of any other party, and that Wtx Hub
            is not in any way responsible for any such use by You, nor for any
            harassing, threatening, defamatory, offensive or illegal messages or
            transmissions that You may receive as a result of using any of the
            Services.
          </p>

          <p>
            In addition, Services that may be accessed from, displayed on or
            linked to from the mobile device are not available in all languages
            or in all countries. Wtx Hub makes no representation that Services
            are appropriate or available for use in any particular location. To
            the extent You choose to access such Services, You do so at Your own
            initiative and are responsible for compliance with any applicable
            laws, including but not limited to applicable local laws. Wtx Hub
            and its licensors reserve the right to change, suspend, remove, or
            disable access to any Services at any time without notice. In no
            event will Wtx Hub be liable for the removal of or disabling of
            access to any such Services. Wtx Hub may also impose limits on the
            use of or access to certain Services without notice or liability.
            Google or Amazon or any third party has no obligation whatsoever to
            furnish any maintenance and support services with respect to the
            Licensed Applications.
          </p>

          <p>
            e. No Warranty. USE OF THE LICENSED APPLICATIONS IS AT YOUR SOLE
            RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT.
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE LICENSED
            APPLICATIONS AND ANY SERVICES PERFORMED OR PROVIDED BY THE LICENSED
            APPLICATIONS ARE PROVIDED “AS IS” AND “AS AVAILABLE”, WITH ALL
            FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND Wtx Hub HEREBY
            DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE LICENSED
            APPLICATIONS AND ANY SERVICES, EITHER EXPRESS, IMPLIED OR STATUTORY,
            INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND CONDITIONS
            OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A
            PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND
            NON-INFRINGEMENT OF THIRD PARTY RIGHTS. Wtx Hub DOES NOT WARRANT
            AGAINST INTERFERENCE WITH YOUR ENJOYMENT OF THE LICENSED
            APPLICATIONS, THAT THE FUNCTIONS CONTAINED IN, OR SERVICES PERFORMED
            OR PROVIDED BY, THE LICENSED APPLICATIONS WILL MEET YOUR
            REQUIREMENTS, THAT THE OPERATION OF THE LICENSED APPLICATIONS OR
            SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS IN THE
            LICENSED APPLICATIONS OR SERVICES WILL BE CORRECTED. NO ORAL OR
            WRITTEN INFORMATION OR ADVICE GIVEN BY WTX HUB OR ITS AUTHORIZED
            REPRESENTATIVE SHALL CREATE A WARRANTY. SHOULD THE LICENSED
            APPLICATIONS OR SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST
            OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION. SOME JURISDICTIONS
            DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON
            APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION
            AND LIMITATIONS MAY NOT APPLY TO YOU.
          </p>
          <p>
            f. Limitation of Liability. TO THE EXTENT NOT PROHIBITED BY LAW, IN
            NO EVENT SHALL WTX HUB BE LIABLE FOR PERSONAL INJURY, OR ANY
            INCIDENTAL, SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER,
            INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF
            DATA, BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR
            LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE
            THE LICENSED APPLICATIONS, HOWEVER CAUSED, REGARDLESS OF THE THEORY
            OF LIABILITY (CONTRACT, TORT OR OTHERWISE) AND EVEN IF Wtx Hub HAS
            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS
            DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF
            INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT
            APPLY TO YOU. In no event shall Wtx Hub’s total liability to You for
            all damages (other than as may be required by applicable law in
            cases involving personal injury) exceed the amount of fifty dollars
            (US$50.00). The foregoing limitations will apply even if the above
            stated remedy fails of its essential purpose.
          </p>
          <p>
            g. Export. You may not use or otherwise export or re-export the
            Licensed Applications except as authorized by English law and the
            laws of the jurisdiction in which the Licensed Applications was
            obtained. In particular, but without limitation, the Licensed
            Applications may not be exported or re-exported (a) into any U.K.
            embargoed countries or (b) to anyone on the U.S. Treasury
            Department’s list of Specially Designated Nationals or the U.S.
            Department of Commerce Denied Person’s List or Entity List. By using
            the Licensed Applications, You represent and warrant that you are
            not located in any such country or on any such list. You also agree
            that you will not use these products for any purposes prohibited by
            English law, including, without limitation, the development, design,
            manufacture or production of nuclear, missiles, or chemical or
            biological weapons.
          </p>
          <p>
            h. Government Users. The Licensed Applications and related
            documentation are “Commercial Items”, as that term is defined at 48
            C.F.R. §2.101, consisting of “Commercial Computer Software” and
            “Commercial Computer Software Documentation”, as such terms are used
            in 48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable.
            Consistent with 48 C.F.R. §12.212 or 48 C.F.R. §227.7202-1 through
            227.7202-4, as applicable, the Commercial Computer Software and
            Commercial Computer Software Documentation are being licensed to
            U.S. Government end users (a) only as Commercial Items and (b) with
            only those rights as are granted to all other end users pursuant to
            the terms and conditions herein. Unpublished-rights reserved under
            the copyright laws of the United States.
          </p>
          <p>
            i. Governing Law. The laws of England, excluding its conflicts of
            law rules, govern this license and Your use of the Licensed
            Applications. Your use of the Licensed Applications may also be
            subject to other local, state, national, or international laws.
          </p>
          <p>
            j. Contact Information. Your questions, complaints or claims with
            respect to the Licensed Applications should be directed to:
          </p>

          <p><a href="http://www.wtxhub.com">Wtx Hub.</a></p>
          <p><a href="mailto:contact@Wtxhub.com">contact@Wtxhub.com</a></p>
          <p>&copy;2020 Wtx Hub</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.appuser-page {
  min-height: 800px;

  .page-header {
    min-height: 500px;
    position: relative;
    background-image: url("../assets/appUser.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }

    .page-header-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 500px;
      background: rgba(0, 0, 0, 0.7);
    }
  }
}

.page-content {
  p {
    font-size: 1.5rem;
    margin: 70px 0;
  }

  h3 {
    &::before {
      content: "|";
      font-size: 3rem;
      color: orange;
      margin-right: 15px;
    }
  }
}
</style>
