<template>
  <div class="endUser-page">
    <div class="page-header">
      <div class="page-header-overlay">
        <h1 class="display-4 text-uppercase">
          Software End User License Agreement
        </h1>
      </div>
    </div>
    <div class="page-content my-4">
      <div class="container">
        <div class="page-content-block">
          <p>
            IMPORTANT – READ CAREFULLY. YOUR USE OF ANY DOWNLOADABLE SOFTWARE
            PRODUCTS, FEATURES OR SERVICES AVAILABLE ON OR THROUGH THE WTX HUB
            WEBSITE IS CONDITIONED UPON YOUR COMPLIANCE WITH, AND ACCEPTANCE OF,
            THE FOLLOWING AGREEMENT. YOU ACKNOWLEDGE AND AGREE THAT BY CLICKING
            ON THE ACCEPT BUTTON OR SIMILAR BUTTONS OR LINKS AS MAY BE
            DESIGNATED BY WTX HUB TO SHOW THESE TERMS AND/OR TO INSTALL THE
            SOFTWARE, YOU ARE ENTERING INTO A LEGALLY BINDING CONTRACT. YOU WILL
            NOT BE PERMITTED TO USE THE SOFTWARE AND THE ASSOCIATED Wtx Hub
            SERVICES UNLESS AND UNTIL YOU ACCEPT THIS AGREEMENT AND ANY OTHER
            RULES OR POLICIES THAT WTX HUB MAY CREATE AND MAKE AVAILABLE ON THIS
            WEBSITE FROM TIME TO TIME.
            <br /><br />
            You represent and warrant that you are authorized to enter into this
            Agreement on behalf of yourself and/or the entity that you purport
            to represent and you agree your registration data is current,
            complete, and accurate. This Agreement will commence on the date
            that you complete the installation of the Software (the “Effective
            Date”).
          </p>
        </div>
        <div class="page-content-block">
          <h3>1. DEFINITIONS.</h3>

          <p>
            1.1 “Content” means any content, including but not limited to
            photographs, caricatures, illustrations, designs, icons, articles,
            text, audio clips and video clips.
          </p>

          <p>
            1.2 “Partner Company” means a company that provides the Software
            and/or associated Wtx Hub services by way of a co-branded or private
            label website.
          </p>

          <p>
            1.3 “Software” means Wtx Hub’s software downloadable or desktop that
            is by Wtx Hub, including the Wtx Hub Mobile Apps, Wtx Hub plug-ins
            and other software of third parties that Wtx Hub offers to its
            Users, which enable Users to create, design, and lay out Content
            with the Software.
          </p>

          <p>
            1.4 “User” refers to a person or entity that has downloaded the
            Software.
          </p>

          <p>
            1.5 “Website” means the Wtx Hub website, currently located at
            http://www.Wtx Hub.com.
          </p>
        </div>
        <div class="page-content-block">
          <h3>2. DELIVERY OF SOFTWARE</h3>
          <p>
            You will download and install the Software from the Website as
            specified by Wtx Hub. During the term, Wtx Hub may, in its sole
            discretion, notify you that it has released an updated version of
            the Software (the “Updated Software”). Upon your receipt of such
            notification, you agree to download the Updated Software and to use
            the Updated Software instead of the prior version. Any Updated
            Software will also be considered “Software” for purposes of this
            Agreement.
          </p>
        </div>
        <div class="page-content-block">
          <h3>3. LICENSE</h3>
          <p>
            Wtx Hub hereby grants to you a royalty-free, nonexclusive,
            non-transferable internal use license under copyright to use,
            display, execute, and perform the Software during the term of this
            Agreement.
            <br /><br />
            You agree that you will not (a) reproduce, modify, distribute,
            transfer, disclose, or make available to any third party any portion
            of the Software (or any related user manuals, documentation,
            screenshots or prints) in any form; (b) reverse engineer, decompile,
            disassemble, or otherwise attempt to derive the source code for the
            Software; or (c) publish any performance or benchmark tests or
            analyses relating to the Software or the use thereof.
            Notwithstanding the foregoing, decompiling the Software is permitted
            to the extent the laws of the jurisdiction where you are located
            give you the right to do so to obtain information necessary to
            render the Software interoperable with other software; provided,
            however, that you must first request such information from Wtx Hub
            and Wtx Hub may, in its discretion, either provide such information
            to you or impose reasonable conditions, including reasonable fees,
            on such use of the Software to ensure that Wtx Hub’s proprietary
            rights in the Software are protected.
          </p>
        </div>
        <div class="page-content-block">
          <h3>4. USER CONTENT</h3>
          <p>
            You represent that the Content that you provide to Wtx Hub shall not
            contain any material (a) protected by copyright, trademark, trade
            secret, patent or any other intellectual property right without
            authorization, or (b) that is defamatory, trade libelous, unlawfully
            threatening or harassing, pornographic, obscene or harmful to
            minors, or (c) that violates any law or regulation, including
            without limitation, the laws and regulations governing export
            control, unfair competition, anti-discrimination, or false
            advertising, and you agree to indemnify and hold Wtx Hub and its
            subsidiaries, affiliates, officers, employees, suppliers, service
            providers and Partner Companies harmless for any claims, losses,
            liabilities and expenses arising out of or relating to any breach of
            this section.
          </p>
        </div>
        <div class="page-content-block">
          <h3>5. SUBMISSIONS</h3>
          <p>
            You acknowledge that Wtx Hub has no obligation to review the Content
            that you provide, but that Wtx Hub reserves the right to (a)
            withhold, remove and/or discard User Content in accordance with its
            then-current User Content Policies and Terms and Conditions, as
            posted on the Website.
          </p>
        </div>
        <div class="page-content-block">
          <h3>6. TITLE</h3>
          <p>
            Wtx Hub shall retain all right, title and interest in the Software
            and in all intellectual property rights therein. No license or other
            rights of any kind are granted or conveyed except for the limited
            internal license expressly provided herein. You shall not offer,
            loan, transfer, encumber, sell or otherwise dispose of the Software
            to any third party without having received prior written
            authorization from Wtx Hub. If you do so, the transfer will be
            deemed void.
          </p>
        </div>
        <div class="page-content-block">
          <h3>7. TERMINATION</h3>
          <p>
            This Agreement and the licenses granted hereunder shall terminate
            immediately if you breach any term or condition hereof. In the event
            of a termination of this Agreement, (a) those sections that by their
            nature are intended by the parties to survive shall survive and
            continue in effect to the extent necessary to protect the rights of
            the parties, including but not limited to Sections 4, 5, 6, 7, 8, 9,
            10, and 11, and (b) you shall cease all use of the Software.
          </p>
        </div>
        <div class="page-content-block">
          <h3>8. DISCLAIMER OF WARRANTIES</h3>
          <p>
            YOU ACKNOWLEDGE THAT THE SOFTWARE PROVIDED MAY CONTAIN BUGS AND
            ERRORS. THE SOFTWARE PROVIDED TO YOU “AS IS” AND ANY USE OF THE
            SOFTWARE IS AT YOUR OWN RISK. TO THE EXTENT LEGALLY PERMITTED UNDER
            THE APPLICABLE LAW, Wtx Hub DISCLAIMS ALL WARRANTIES, WHETHER
            EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION, ANY
            IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT OF THIRD PARTY RIGHTS,
            MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. SOME
            JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO
            THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
          </p>
        </div>
        <div class="page-content-block">
          <h3>9. LIMITATION OF LIABILITY</h3>
          <p>
            YOU AGREE THAT TO THE EXTENT LEGALLY PERMITTED UNDER THE APPLICABLE
            LAW, WTX HUB SHALL NOT BE RESPONSIBLE FOR ANY LOSS OR DAMAGE TO YOU,
            YOUR CUSTOMERS OR THIRD PARTIES CAUSED BY FAILURE OF THE SOFTWARE TO
            FUNCTION. IN NO EVENT WILL WTX HUB BE LIABLE FOR ANY SPECIAL,
            CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, OR INDIRECT DAMAGES
            (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOST PROFITS,
            COST OF SUBSTITUTE GOODS, LOST DATA OR BUSINESS INTERRUPTION) IN
            CONNECTION WITH THE USE OF THE SOFTWARE OR IN CONNECTION WITH ANY
            OTHER CLAIM ARISING FROM THIS AGREEMENT, EVEN IF WTX HUB HAS BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE AGGREGATE LIABILITY
            OF WTX HUB ARISING FROM OR RELATING TO THIS AGREEMENT AND THE
            SOFTWARE, REGARDLESS OF THE FORM OF ACTION OR CLAIM (E.G., CONTRACT,
            WARRANTY, TORT, STRICT LIABILITY, NEGLIGENCE, FRAUD OR OTHER LEGAL
            THEORY) IS LIMITED TO THE AMOUNTS PAID BY YOU TO WTX HUB DURING THE
            SIX MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO LIABILITY.
            NOTHING IN THIS AGREEMENT SHALL LIMIT OR EXCLUDE WTX HUB’s LIABILITY
            FOR GROSS NEGLIGENCE OR INTENTIONAL MISCONDUCT OF WTX HUB OR ITS
            AGENTS OR EMPLOYEES, OR FOR DEATH OR PERSONAL INJURY. APPLICABLE LAW
            MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT
            APPLY TO YOU.
          </p>
        </div>
        <div class="page-content-block">
          <h3>10. CONFIDENTIALITY</h3>
          <p>
            Wtx Hub considers the Software and any technical information,
            evaluation or reports supplied to you to be proprietary, and you
            agree to treat the Software as confidential material in a manner no
            less protective than you use to protect your own similar assets, but
            in no event will you use less than reasonable care to protect the
            Software. Except as provided herein, you agree not to permit any
            third party access to the Software, nor to any materials generated
            by Wtx Hub or you regarding the Software without Wtx Hub’s advance
            written approval.
          </p>
        </div>
        <div class="page-content-block">
          <h3>11. GENERAL PROVISIONS.</h3>
          <p>
            11.1 Feedback. In the event that you provide Company with feedback
            regarding the use, operation or functionality of the Software
            (“Feedback”), including but not limited to information about
            operating results, known or suspected bugs, errors or compatibility
            problems, or desired features, you hereby assign to Wtx Hub all
            rights in the Feedback and agree that Wtx Hub shall have the right
            to use the Feedback and related information in any manner it deems
            appropriate.
          </p>
          <p>
            11.2 Governing Law, Venue and Arbitration. This Agreement shall be
            governed by the laws of England without giving effect to any
            conflict of laws principles that may provide the application of the
            law of another jurisdiction. You and Wtx Hub agree to submit to the
            jurisdiction of, and agree that venue is proper in, the courts
            located in England, in such legal action or proceeding. The
            application of the United Nations Convention on the International
            Sale of Goods is hereby expressly excluded. Any claim or dispute
            (excluding claims for injunctive or other equitable relief as set
            forth below) in connection with this Agreement may be resolved in a
            cost effective manner through binding non-appearance-based
            arbitration, by mutual consent. Such arbitration shall be initiated
            through an established alternative dispute resolution provider (“ADR
            Provider”) that offers arbitration as set forth in this section and
            under the rules of such ADR Provider, except to the extent such
            rules are in conflict with this Agreement. The party demanding
            arbitration will propose an ADR Provider and the other party shall
            not unreasonably withhold consent to use such ADR Provider. The ADR
            Provider and the parties must comply with the following rules: a)
            the arbitration shall be conducted by telephone, online and/or be
            solely based on written submissions, the specific manner shall be
            chosen by the party initiating the arbitration; b) all arbitration
            proceedings shall be held in English; c) the arbitration shall not
            involve any personal appearance by the parties or witnesses unless
            otherwise mutually agreed by the parties; and d) any judgment on the
            award rendered by the arbitrator may be entered in any court of
            competent jurisdiction. Each party shall bear its own costs
            (including attorney fees) and disbursements arising out of the
            arbitration, and shall pay an equal share of the fees and costs of
            the ADR Provider. You acknowledge that any breach of this Agreement
            by you would cause irreparable injury to Wtx Hub for which monetary
            damages would not be an adequate remedy and, therefore, Wtx Hub will
            be entitled to seek injunctive relief (including specific
            performance) in any court of competent jurisdiction.
          </p>
          <p>
            11.3 Severability; Language. If any provision of this Agreement is
            found to be invalid or unenforceable, such provision will be changed
            and interpreted to accomplish the objectives to the greatest extent
            possible under any applicable law and the remaining provisions will
            continue in full force and effect. The parties of this Agreement
            have expressly required that the present Agreement be drawn up in
            the English language.
          </p>

          <p>
            11.4 No Assignment. This Agreement, and your rights and obligations
            herein, may not be assigned, subcontracted, delegated, or otherwise
            transferred by you without Wtx Hub’s prior written consent, and any
            attempted assignment, subcontract, delegation, or transfer in
            violation of the foregoing will be null and void. The terms of this
            Agreement shall be binding upon assignees.
          </p>

          <p>
            11.5 Export. You agree not to export, directly or indirectly, the
            Software, any technical data acquired from Wtx Hub, or any products
            utilizing such data to countries which export may be in violation of
            export laws or regulations.
          </p>

          <p>
            11.6 Waiver. Any waiver or failure to enforce any provision of this
            Agreement on one occasion will not be deemed a waiver of any other
            provision or of such provision on any other occasion.
          </p>
          <p>
            11.7 Entire Agreement. This Agreement including the Wtx Hub Terms
            and Conditions and the Privacy Policy, which are hereby incorporated
            by reference, is the final, complete and exclusive agreement of the
            parties with respect to the subject matter hereof and supersedes and
            merges all prior discussions between the parties. No modification of
            or amendment to this Agreement, nor any waiver of any rights under
            this Agreement, will be effective unless in writing and signed by
            the party to be charged (which, in the case of the Wtx Hub, shall
            require the signature of a duly authorized officer of the Wtx Hub).
          </p>
        </div>

        <div class="page-content-block">
          <h3>12. International Provisions.</h3>
          <p>
            The following provisions shall apply only if you are located in the
            countries listed below.
          </p>
          <p>
            12.1 Germany. Notwithstanding anything contrary in Section 9, Wtx
            Hub is also not liable for acts of simple negligence (unless they
            cause injuries to or death of any person), except when they are
            caused by a breach of any substantial contractual obligations
            (vertragswesentliche Pflichten).
          </p>
        </div>

        <div class="page-content-block">
          <h3>13. A third party</h3>
          <p>
            who is not a party to this Agreement has no right under the
            Contracts (Rights of Third Parties) Act 1999 to enforce any term of
            this Agreement, but this does not affect any right or remedy of such
            third party which exists or is available apart from that Act.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.endUser-page {
  min-height: 800px;

  .page-header {
    min-height: 500px;
    position: relative;
    background-image: url("../assets/endUser.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }

    .page-header-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 500px;
      background: rgba(0, 0, 0, 0.7);
    }
  }
}

.page-content {
  p {
    font-size: 1rem;
    margin: 20px 0;
  }

  h3 {
    &::before {
      content: "|";
      font-size: 3rem;
      color: orange;
      margin-right: 15px;
    }
  }

  ul {
    li {
      list-style-type: none;
    }
  }
}
</style>
