<template>
  <div class="business-products py-5">
    <div class="container">
      <h2 class="mb-5">Business Products:</h2>
      <div class="row">
        <div
            class="col-md-4 mb-5"
            v-for="product in businessProducts"
            :key="product.id"
        >
          <div class="card h-100 text-center ">
            <img
                :src="product.coverImg"
                class="card-img-top"
                alt="product.title"
            />
            <div class="card-body">
              <h5 class="card-title font-weight-bold">
                {{ product.title }}
              </h5>
              <p class="card-text text-muted">{{ product.desc }}</p>
            </div>
            <div class="card-footer">
              <a :href="product.link" class="btn btn-warning" target="_blank">Get the App</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: mapGetters(["businessProducts"])
};
</script>

<style lang="scss">
.business-products {
  img {
    height: 200px;
  }

  .card-footer {
    background-color: white !important;
    border-top: none !important;
  }
}
</style>
