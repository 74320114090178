<template>
  <div class="terms-page">
    <div class="page-header">
      <div class="page-header-overlay">
        <h1 class="display-4 text-uppercase">Terms & Conditions</h1>
      </div>
    </div>
    <div class="page-content my-4">
      <div class="container">
        <div class="page-content-block">
          <h3>TERMS AND CONDITIONS</h3>
          <p>
            These terms and conditions ("Terms") apply to your use of Wtx Hub’s
            mobile ordering platform. All services provided by Wtx Hub,
            including the services, information, features and functionality
            offered through our website at www.WtxHub.com (the "Website") and
            our mobile applications (“the Application”) are referred to herein
            as the “Service”.
          </p>
          <p>
            PLEASE READ THESE TERMS AND CONDITIONS OF SERVICE CAREFULLY AS THEY
            CONTAIN IMPORTANT INFORMATION REGARDING YOUR USE OF THE SERVICE.
            THESE TERMS AFFECT YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. BY
            ACCESSING OR USING THE SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ,
            UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE
            TO THESE TERMS, IN WHOLE OR IN PART, THEN YOU ARE NOT PERMITTED TO
            USE OUR SERVICE.
          </p>
          <p>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time without incurring any liability or
            obligation. See below under the heading “Modification of The Service
            and Terms” for more information.
          </p>
          <p>
            The personal information you provide through the Service shall be
            collected, used, managed and disclosed in accordance with our
            Privacy Policy.
          </p>
        </div>
        <div class="page-content-block">
          <h3>Wtx Hub</h3>
          <p>
            These Terms are between you and Wtx Hub (“Wtx Hub”) .When used in
            these Terms, the terms “us”, “we” and “our” refer to Wtx Hub.
          </p>
        </div>
        <div class="page-content-block">
          <h3>WHAT WE DO</h3>
          <p>
            We provide a way for you to order (“Order”) carbon offsets or
            services (“Products”) from third party projects (“Projects”). Wtx
            Hub is a limited agent for its Project partners with the authority
            to offer a Project’s Products for sale at the price shown through
            the Service. Wtx Hub has the authority to accept or decline your
            Order on behalf of the Project and collect payment for your Order on
            behalf of the Project. Wtx Hub also has the authority to provide
            certain discounts and promotional offers in connection with Products
            offered through the Service. Wtx Hub does not provide the Products
            itself or operate any of the Projects, and is not itself a Project.
            Wtx Hub is not responsible for the quality, character or safety of
            any Products available through the Service, that is the sole
            responsibility of the Project. The provision of the Product and
            fulfilment of any Orders by the Project to you constitutes an
            agreement entered into between you and the Project. Wtx Hub shall
            never be a party to such agreement.
          </p>
        </div>
        <div class="page-content-block">
          <h3>SIGNING UP</h3>
          <p>
            You have to be at least the age of majority or older in your
            jurisdiction of residence in order to use the Service. If you reside
            in a jurisdiction that restricts the use of the Service because of
            your age, or restricts the ability to enter into contracts such as
            this one due to age, you must abide by such age limits and you must
            not use the Service. By using the Service, you are entering into a
            contract with Wtx Hub under these Terms. You warrant that the
            information you provide to Wtx Hub is accurate and complete and that
            you shall keep the information you have provided to Wtx Hub current
            through the Service. Wtx Hub is entitled at all times to verify the
            information that you have provided and to refuse use of the Service
            without providing reasons. <br /><br />
            In connection with your use of the Service, Wtx Hub can send you
            electronic service messages. These electronic messages can be sent
            for various reasons, including to provide a receipt for your Order,
            for security purposes (e.g. verifying that your smartphone is
            connected to the phone number you entered upon registration) and for
            issues related to an Order you placed (e.g. if the Project is out of
            stock of an item you ordered, you can be notified by text message).
            For information about promotional and marketing messages, please
            refer to our Privacy Policy.
          </p>
        </div>
        <div class="page-content-block">
          <h3>MAKING AN ORDER</h3>
          <p>
            Wtx Hub has the sole and complete discretion to accept or reject
            each Order on behalf of the Project.
            <br />
            <br />

            Once you have submitted your Order, you shall not be entitled to
            change or cancel your Order, nor shall you be entitled to a refund
            of your Order. Wtx Hub shall notify you as soon as reasonably
            practicable if your Order is rejected for any reason. Your Order can
            be rejected at any time because the Project is too busy, due to
            weather conditions or for any other reason. If your Order is
            accepted, payment shall be authorized by Wtx Hub and you shall be
            provided an estimate on when your Order shall be ready. Neither Wtx
            Hub nor the Project guarantee that Orders shall be ready for pick-up
            within the estimated times.

            <br /><br />
            If you have any issues with your Order (including any issue
            regarding the accuracy of information provided through the Service),
            or wish to change or cancel your Order after it has been accepted,
            you can contact us directly (contact@WtxHub.com) and we shall assist
            you in resolving your issue. We cannot guarantee that we can resolve
            any complaint or issue you have because resolution is at the sole
            discretion of the Project once an Order has been accepted. If we are
            unable to assist you in resolving a complaint, you shall need to
            address it directly with the Project. All complaints regarding the
            quality of any Products should be addressed directly to the Project.
            Wtx Hub has no obligation to compensate you for any dispute you can
            have with any Project.
          </p>
        </div>
        <div class="page-content-block">
          <h3>ACCEPTABLE USE</h3>
          <p>
            You can only access the Service using the means and methods
            permitted by Wtx Hub. It is your responsibility to ensure you
            download the correct Application for your device. Wtx Hub is not
            liable if you do not have a compatible mobile device or if you
            download the wrong version of the Application for your mobile
            device. Wtx Hub reserves the right to terminate your use of the
            Service should you be using the Service with an incompatible or
            unauthorised device. <br /><br />
            By using the Service, you further agree that: You shall only use the
            Service or download the Application for your sole, personal use and
            shall not resell it to a third party; You shall not authorize others
            to use your account; You shall not assign or otherwise transfer your
            account to any other person or legal entity; You shall not use the
            Service for unlawful purposes, including but not limited to sending
            or storing any unlawful material; You shall not use the Service to
            cause nuisance, annoyance or inconvenience; You shall not impair the
            proper operation of any network accessed through the Service; You
            shall not try to harm the Service in any way whatsoever; You shall
            not copy or distribute the Website or Application without written
            permission from Wtx Hub; You shall keep secure and confidential your
            account password or any identification we provide you which allows
            access to the Service; You shall provide us with whatever proof of
            identity we can reasonably request; You shall only use the Service
            through an internet connection you are authorized to use; You shall
            not use the Service with an incompatible or unauthorized device; and
            You shall comply with all applicable laws including the laws of the
            area in which you are present while using the Service.
            <br /><br />
            Wtx Hub reserves the right to immediately terminate your use of the
            Service should you not comply with any of the above rules. Wtx Hub
            shall have the right to investigate and prosecute violations of any
            of the above to the fullest extent of the law. Wtx Hub can involve
            and cooperate with law enforcement authorities in prosecuting users
            who violate these Terms. You acknowledge that Wtx Hub has no
            obligation to monitor your access to or use of the Service, but has
            the right to do so for the purpose of operating the Service, to
            ensure your compliance with these Terms, or to comply with
            applicable law or the order or requirement of a court,
            administrative agency or other governmental body.
          </p>
        </div>
        <div class="page-content-block">
          <h3>PAYMENT</h3>
          <p>
            The Service is available to you free of charge. Wtx Hub reserves the
            right to introduce a fee for the use of the Service. If Wtx Hub
            decides to introduce such a fee, Wtx Hub shall inform you
            accordingly. <br /><br />
            Wtx Hub shall charge you on behalf of the Project for the Order
            placed by you. You agree that you shall pay for all Products you
            purchase from the Project, and that Wtx Hub can charge you the total
            amount for your Order using the payment method provided by you
            (including any taxes and late fees, as applicable). You are
            responsible for the timely payment of all fees and for providing Wtx
            Hub with a valid method of payment at all times. Any payment made is
            non-refundable.
            <br /><br />
            Wtx Hub uses a third-party payment processor (the "Payment
            Processor") to link the payment card you provide to the Service, and
            by using this third-party service, you agree to its terms and
            conditions of service.
            <br /><br />
            The processing of payments,credits or points as applicable, in
            connection with your use of the Service shall be subject to the
            terms, conditions and privacy policies of your credit card issuer in
            addition to these Terms. Wtx Hub is not responsible for any errors
            by the Payment Processor.
          </p>
        </div>
        <div class="oage-content-block">
          <h3>PROMOTIONS AND REWARDS</h3>
          <p>
            Wtx Hub can, in Wtx Hub's sole discretion, provide offers, programs
            and promotions (“Promos”)and that allow you to obtain account
            credits, Employer Points, Bonus Points, or other features or
            benefits related to the Services and/or a third party provider's
            services (collectively, “Promo Benefits”). Promos can be subject to
            additional terms and conditions that Wtx Hub establishes from time
            to time. You agree that: <br /><br />
            Promos must be used for the intended audience and purpose, and in a
            lawful manner; Promos can not be duplicated, sold or transferred in
            any manner, or made available to the general public (whether posted
            to a public form or otherwise), unless expressly permitted by Wtx
            Hub; Promos can be changed, terminated or disabled by Wtx Hub at any
            time for any reason without prior notice or liability to Wtx Hub;
            <br /><br />
            One Promo cannot be combined with any other Promo unless expressly
            permitted by Wtx Hub in the terms of the Promo; Promo Benefits can
            only be used pursuant to the specific terms that Wtx Hub establishes
            for the applicable Promo; and Promo Benefits are not redeemable for
            cash and can be subject to expiration or termination conditions.
            <br /><br />
            Certain Promos can only be available to new users. New users are
            people who have not yet created a Wtx Hub account. New users can
            also be required to complete an Order before being eligible for any
            Promo offer and the Promo Benefits. Additional terms and conditions
            specific to new user Promos can be specified by Wtx Hub from time to
            time.
            <br /><br />
            Wtx Hub reserves the right to delay delivery of Promo Benefits
            pending verification that a user has complied with the terms of the
            Promo, including verification of the user's identity. Wtx Hub users
            that make a referral code available to others must not make any
            false or misleading representations related to Wtx Hub or the
            benefits a new user can receive upon signing-up for Wtx Hub. Wtx Hub
            reserves the right to deduct or cancel Promo Benefits in the event
            that Wtx Hub determines or believes that the use or redemption of
            the Promo was in error, fraudulent, illegal, or in violation of the
            applicable Promo terms or these Terms.
            <br /><br />
            Account credits provided by Wtx Hub, whether as a Promo Benefit,
            through the redemption of Points or otherwise provided by Wtx Hub
            through any means, have no cash value and are not transferable. If a
            purchase on Wtx Hub exceeds the account credit value in your
            account, the remaining balance shall be charged to the registered
            credit card. Account credits can be cancelled and removed from your
            account. Unless otherwise specified in the particular Promo, account
            credits are subject to cancellation by Wtx Hub at any time and can
            be removed from your account at Wtx Hub’s discretion.
            <br /><br />
            Unless otherwise prohibited by applicable law, Wtx Hub reserves the
            right to cancel and/or expire any rewards points in your account
            after one year of account inactivity.
          </p>
        </div>
        <div class="page-content-block">
          <h3>INDEMNIFICATION</h3>
          <p>
            By accepting these Terms and using the Service, you agree that you
            shall indemnify and hold harmless Wtx Hub, its affiliates,
            licensors, and each of their officers, directors, other users,
            employees, legal representatives and agents (collectively, “Wtx Hub
            Group”) from and against any and all claims, costs, damages, losses,
            liabilities and expenses (including legal fees and costs) arising
            out of or in connection with: (i) your violation or breach of these
            Terms or any applicable law or regulation, whether or not referenced
            herein; (ii) your violation of any rights of any third party,
            including, without limitation, Projects arranged via the Service; or
            (iii) your use or misuse of the Service.
          </p>
        </div>
        <div class="page-content-block">
          <h3>LIABILITY</h3>
          <p>
            THE LEGISLATION OF CERTAIN JURISDICTIONS DOES NOT ALLOW FOR CERTAIN
            EXCLUSIONS OF LIABILITY, SO THAT SOME OF THE PROVISIONS BELOW CANNOT
            APPLY TO YOU. FOR THE AVOIDANCE OF DOUBT NOTHING IN THESE TERMS
            SHALL AFFECT YOUR STATUTORY RIGHTS. NOTHING IN THESE TERMS SHALL
            LIMIT OR EXCLUDE OUR LIABILITY TO YOU TO THE EXTENT THAT, BY
            APPLICABLE LAW, SUCH LIABILITY CANNOT BE SO LIMITED OR EXCLUDED.
            <br /><br />
            YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS
            PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT ANY
            GUARANTEES, REPRESENTATIONS OR WARRANTIES OF ANY KIND (WHETHER
            EXPRESS OR IMPLIED), ALL OF WHICH ARE HEREBY DISCLAIMED BY US TO THE
            FULLEST EXTENT PERMITTED BY LAW. WITHOUT LIMITING THE GENERALITY OF
            THE FOREGOING, Wtx Hub DOES NOT REPRESENT, WARRANT, OR GUARANTEE
            THAT: (I) THE APPLICATION OR SERVICE SHALL MEET YOUR REQUIREMENTS;
            (II) THE APPLICATION OR SERVICE SHALL BE UNINTERRUPTED, TIMELY,
            SECURE OR ERROR-FREE; OR (III) ANY PRODUCTS, SERVICES, INFORMATION
            OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE
            SHALL MEET YOUR PERSONAL EXPECTATIONS OR BE OF A CERTAIN QUALITY.
            <br /><br />
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU EXPRESSLY
            UNDERSTAND AND AGREE THAT NO MEMBER OF THE WTX HUB GROUP SHALL BE
            LIABLE TO YOU FOR ANY DAMAGES WHATSOEVER, INCLUDING LOSS OF PROFITS,
            REVENUE, ECONOMIC ADVANTAGE, GOODWILL, DATA, OPPORTUNITY OR SALES,
            DAMAGES FOR PERSONAL INJURY, OR FOR ANY PUNITIVE, INDIRECT,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES OR OTHER
            INTANGIBLE LOSSES (WHETHER OR NOT THE WTX HUB GROUP HAS BEEN ADVISED
            OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (I) THE USE OR
            THE INABILITY TO USE THE SERVICE, INCLUDING DAMAGES CAUSED BY
            MALWARE, VIRUSES OR ANY INCORRECTNESS OR INCOMPLETENESS OF ANY
            CONTENT YOU CAN ACCESS; (II) THE COST OF PROCUREMENT OF SUBSTITUTE
            GOODS AND SERVICES; (III) UNAUTHORIZED ACCESS TO, LOSS, OR
            ALTERATION OF YOUR TRANSMISSIONS OR DATA SUBMITTED TO OR RECEIVED
            FROM THE SERVICE; OR (IV) ANY OTHER MATTER RELATING TO THE SERVICE.
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU WAIVE,
            RELEASE AND DISCHARGE THE WTX HUB GROUP FROM ALL LEGAL CLAIMS,
            DEMANDS, DAMAGES, ACTIONS OR CAUSES OF ACTION IN RESPECT OF THE
            FOREGOING.WTX HUB SHALL NOT BE LIABLE OR RESPONSIBLE FOR ANY FAILURE
            TO PERFORM, OR DELAY IN PERFORMANCE OF, ANY OF OUR OBLIGATIONS UNDER
            THESE TERMS THAT IS CAUSED BY EVENTS OUTSIDE OUR REASONABLE CONTROL.
            <br /><br />
            WITHOUT PREJUDICE TO THE FOREGOING, AND INSOFAR AS ALLOWED UNDER
            APPLICABLE LAW, THE AGGREGATE LIABILITY OF THE WTX HUB GROUP TO YOU
            IN CONNECTION WITH THESE TERMS AND YOUR USE OF THE SERVICE SHALL IN
            NO EVENT EXCEED US$50.00.
          </p>
        </div>
        <div class="page-content-block">
          <h3>CONTENT</h3>
          <p>
            For the purpose of these Terms, the following definitions apply:
            "Content" means all content featured or displayed, including, but
            not limited to, logos, icons, trademarks, text, graphics text,
            graphics, photographs, images, moving images, sound, illustrations,
            music, software (excluding the Application), opinions, remarks,
            comments, artwork, links, questions, suggestions, information or
            other materials.
            <br />
            "Wtx Hub Content" means Content owned or used by Wtx Hub, its
            affiliates or third-party licensors (including Projects) and made
            available through the Service, but excluding User Content. "User"
            means a person who accesses or uses the Service. "User Content"
            means Content that a User posts, uploads, publishes, submits or
            transmits to be made available through the Service.
            <b></b>
            "Collective Content" means, collectively, Wtx Hub Content and User
            Content. Subject to your compliance with these Terms, Wtx Hub grants
            you a limited, revocable, non-exclusive, non-transferable license:
            to view, download and print any Wtx Hub Content solely for your
            personal and non-commercial purposes; and to view any User Content
            to which you are permitted access solely for your personal and
            non-commercial purposes. Unless otherwise stated, the copyright and
            other intellectual property rights in the Wtx Hub Content are owned
            by us, the Projects or our other licensors. These works are
            protected by copyright laws, trademark laws, and other intellectual
            property laws and treaties, both in the United Kingdom and around
            the world and all rights therein are reserved. For the purposes of
            these Terms, any use of extracts from the Wtx Hub Content other than
            in accordance with these Terms is prohibited. You have no right to
            sublicense the license rights granted in this section.
            <br />
            You can not use, copy, adapt, modify, create derivative works from,
            distribute, license, sell, transfer, publicly display, publicly
            perform, reproduce, transmit, stream, broadcast or otherwise exploit
            the Collective Content, except as expressly permitted in these
            Terms. You can not reuse any Collective Content without first
            obtaining the written consent of Wtx Hub. No licenses or rights are
            granted to you by implication or otherwise under any intellectual
            property rights owned or controlled by Wtx Hub or its licensors,
            except for the licenses and rights expressly granted in these Terms.
            Any rights not expressly granted in these Terms are reserved. We
            can, in our sole discretion, permit Users to post, upload, publish,
            submit or transmit User Content on through the Service. User Content
            shall be deemed non-confidential and non-proprietary. By providing
            User Content to Wtx Hub, you grant Wtx Hub a non-exclusive,
            perpetual, transferable, worldwide, royalty-free, right to use,
            copy, distribute, display, modify, make derivative works from, and
            disclose to third parties any User Content for any purpose, in any
            medium and throughout the world. You hereby waive any moral rights
            in your User Content in favour of Wtx Hub.
            <br />
            You acknowledge that Wtx Hub only acts as a passive conduit for the
            distribution of the User Content and is not responsible or liable to
            you or to any third party for the content or accuracy of the User
            Content. Wtx Hub does not continuously monitor User Content
            published by you or moderate between Users, nor shall Wtx Hub be
            under an obligation to do so. Without limiting the foregoing, you
            acknowledge and agree that any remarks, opinions, comments,
            suggestions and other information expressed or included in the User
            Content do not necessarily represent those of Wtx Hub.
            <br />
            Any use by you of the User Content is entirely at your own risk. You
            represent and warrant that any User Content posted or transmitted by
            you is original to you and does not copy the work of any third party
            or otherwise infringe any third party intellectual property rights,
            rights of privacy or personality rights and does not contain any
            defamatory, vulgar, illegal, or disparaging statements. Furthermore,
            you represent and warrant that you have the capacity to grant the
            license as stipulated in this paragraph.
            <br />
            You agree to indemnify and hold harmless the Wtx Hub Group against
            all costs, expenses, damages, losses and liabilities incurred or
            suffered by any member of the Wtx Hub Group related to any User
            Content posted or transmitted by you through the Service.

            <br />
            Wtx Hub reserves the right at its sole discretion to block or remove
            (in whole or in part) any User Content posted or transmitted by you
            and which Wtx Hub believes is not in accordance with these Terms
            (including without limitation materials which infringe or can
            infringe third party intellectual property rights, rights of privacy
            or personality rights), or is otherwise unacceptable to Wtx Hub. You
            agree to provide to Wtx Hub sufficient information to enable Wtx Hub
            to investigate whether such User Content breaches these Terms. Wtx
            Hub shall take such action as Wtx Hub in its sole discretion
            decides. However, Wtx Hub does not warrant or represent that it
            shall block or remove (in whole or in part) or monitor such User
            Content.
          </p>
        </div>
        <div class="page-content-block">
          <h3>INTELLECTUAL PROPERTY OWNERSHIP</h3>
          <p>
            Wtx Hub alone (and its third party licensors, where applicable)
            shall own all right, title and interest, including all related
            intellectual property rights, in and to the Website, Application and
            the Service and any suggestions, ideas, enhancement requests,
            feedback, recommendations or other information provided by you or
            any other party relating to the Website, Application or the Service.
            <br /><br />
            These Terms do not constitute a sale and do not convey to you any
            rights of ownership in or related to the Website, the Application or
            the Service, or any intellectual property rights owned by Wtx Hub.
            Wtx Hub's name, logo, and the product names associated with the
            Service are trademarks of Wtx Hub, its affiliated companies or third
            parties, and no right or license is granted to use them.
          </p>
        </div>
        <div class="page-content-block">
          <h3>THIRD PARTY INTERACTIONS</h3>
          <p>
            Wtx Hub alone (and its third party licensors, where applicable)
            During use of the Service, you can enter into correspondence with,
            purchase Greats or services from, or participate in promotions of
            Projects, advertisers or sponsors through a link on the Website or
            through the Application or Service. These links take you off the
            Website, the Application and the Service and are beyond Wtx Hub's
            control. The websites you can link to have their own separate terms
            and conditions as well as a privacy policy. Wtx Hub is not
            responsible and cannot be held liable for the content and activities
            of these websites. You therefore visit or access these websites
            entirely at your own risk.
            <br /><br />
            Please note that these other websites can send their own cookies to
            users, collect data or solicit personal information, and you are
            therefore advised to check the terms of use or privacy policies on
            those websites prior to using them.
          </p>
        </div>
        <div class="page-content-block">
          <h3>TERMINATION</h3>
          <p>
            You are entitled to terminate your use of the Service at any time by
            permanent deletion of the Application installed on your smart phone,
            thus disabling the use by you of the Application and the Service.
            You can close your user account at any time by following the
            instructions on Wtx Hub's website. Wtx Hub is entitled to terminate
            your use of the Service at any time without notice and with
            immediate effect (by disabling your use of the Application and the
            Service) for any reason, including, if you: violate or breach any
            term of these Terms, or in the opinion of Wtx Hub, misuse the
            Application or the Service.I
          </p>
        </div>
        <div class="page-content-block">
          <h3>ENTIRE AGREEMENT</h3>
          <p>
            The Terms (including any referenced documents) constitute the entire
            agreement between you and Wtx Hub and governs your use of the
            Service, superseding any prior version of these Terms between you
            and Wtx Hub.
          </p>
        </div>
        <div class="page-content-block">
          <h3>WAIVER AND SEVERABILITY OF TERMS</h3>
          <p>
            The failure of Wtx Hub to exercise or enforce any right or provision
            of the Terms shall not constitute a waiver of such right or
            provision. If any provision of the Terms is found by a court of
            competent jurisdiction or arbitrator to be invalid, the parties
            nevertheless agree that the court or arbitrator should endeavour to
            give effect to the parties' intentions as reflected in the
            provision, and the other provisions of the Terms remain in full
            force and effect.
          </p>
        </div>
        <div class="page-content-block">
          <h3>MODIFICATION OF THE SERVICE AND TERMS</h3>
          <p>
            Wtx Hub reserves the right, at its sole discretion to change,
            suspend, or discontinue the Service (including without limitation,
            the availability of any feature, database, or content) at any time.
            Wtx Hub can also impose limits on certain features and services or
            restrict your access to parts or all of the Service without notice
            or liability.
            <br /><br />
            Wtx Hub reserves the right, at its sole discretion, to modify or
            replace any of these Terms. If we change these Terms, we shall
            provide notice to you by posting the revised Terms on our Website
            and by indicating at the top of this page the date these Terms were
            last updated. Such changes shall be effective when the revised Terms
            are posted to our Website. You should check the Terms frequently for
            any revisions, and especially before your use of the Service. By
            continuing to access or use the Service after those revisions become
            effective, you agree to be bound by the revised Terms. If you do not
            agree to the new Terms, in whole or in part, please stop using the
            Service.
          </p>
        </div>
        <div class="page-content-block">
          <h3>NOTICE</h3>
          <p>
            Wtx Hub can give notice by means of a general notice sent through
            the Application, or by electronic mail to your email address on
            record in Wtx Hub's account information, or by written communication
            sent by regular mail to your address on record in Wtx Hub's account
            information.
          </p>
        </div>
        <div class="page-content-block">
          <h3>ASSIGNMENT</h3>
          <p>
            You can not assign your rights under these Terms without prior
            written approval of Wtx Hub.
          </p>
        </div>
        <div class="page-content-block">
          <h3>APPLICABLE LAW AND DISPUTE RESOLUTION</h3>
          <p>
            To the fullest extent permitted by applicable law, you irrevocably
            and unconditionally submit to the exclusive jurisdiction of the
            English courts with respect to any dispute, controversy or claim (a
            “Dispute”) arising out of or in connection with these Terms or
            <br /><br />
            your use of the Service. This contract is governed by English Law.
            WHERE PERMITTED UNDER APPLICABLE LAW, YOU AND WTX HUB AGREE THAT
            EACH CAN BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS
            INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
            PURPORTED CLASS OR REPRESENTATIVE ACTION. Unless both you and Wtx
            Hub agree, no arbitrator or judge can consolidate more than one
            person’s claims or otherwise preside over any form of a
            representative or class proceeding. You agree that any Dispute shall
            be submitted to and determined by binding arbitration in England.
            The language of arbitration shall be English.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.terms-page {
  min-height: 800px;

  .page-header {
    min-height: 500px;
    position: relative;
    background-image: url("../assets/terms&conditions.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }

    .page-header-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 500px;
      background: rgba(0, 0, 0, 0.7);
    }
  }
}

.page-content {
  p {
    font-size: 1rem;
    margin: 20px 0;
  }

  h3 {
    &::before {
      content: "|";
      font-size: 3rem;
      color: orange;
      margin-right: 15px;
    }
  }
}
</style>
